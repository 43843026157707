import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

const NoteAndTicketScanner = ({ data }) => {
  return (
    <Layout>
      <SEO title="JetScan iFX® i200 note and ticket scanner" />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="flex flex-wrap w-full pb-8 mx-auto mb-8 border-b-2 border-transparent max-w-7xl">
        <div className="flex items-center justify-center w-full md:w-1/2">
          <div className="w-full max-w-lg px-4 mx-auto">
            <h2 className="text-sm font-bold leading-none tracking-wide uppercase text-brand-blue">
              JetScan iFX® i200 note and ticket scanner
            </h2>
            <h3 className="mb-6 text-3xl font-bold leading-none">
              Process next generation banknotes quickly and easily
            </h3>
            <p className="mb-4">
              JetScan iFX®i200 series of currency and ticket scanner offers 20%
              faster currency processing with two fully functional pockets for
              increased productivity. Confidently process next generation
              banknotes on the JetScan iFX i200.
            </p>
            <p className="mb-4">
              Count, image, sort and face mixed currency at an industry-leading
              speed of up to 1,200 mixed notes per minute. Minimise the time
              employees spend counting cash, using the fastest two-pocket
              currency counting machine available.
            </p>
            <p className="mb-4">
              Counterfeit note handling is unsurpassed with the optional ticket
              barcode reading and image capture features. Every ticket’s barcode
              can be captured and stored. Stacker box contents can be processed
              including both notes and tickets at up to 1,200 notes/tickets per
              minute while separating tickets from notes – all in one pass.
            </p>
            <ul className="mb-4 ml-4 list-disc">
              <li>
                <strong>Fastest two-pocket currency counter:</strong> 20% faster
                with 60% fewer rejects gives you the fastest currency counter
                available. Boost productivity, reduce FTE costs, and improve
                morale
              </li>
              <li>
                <strong>Mixed note processing:</strong> Count and denominate up
                to 1,200 mixed notes per minute to end shifts on time and avoid
                overtime costs
              </li>
              <li>
                <strong>Simple jam reconciliation:</strong> Clear jams quickly
                and easily with minimal impact on processing. Access the entire
                note path from the front of the machine
              </li>
              <li>
                <strong>Easy-to-use:</strong> Change processing modes with the
                touch of a button on an intuitive colour touch-screen display.
                Option to show only the buttons needed for daily operation
              </li>
              <li>
                <strong>Ticket barcode reading:</strong> Read gaming ticket
                barcodes at up to 1,200 tickets per minute while simultaneously
                processing and separating banknotes
              </li>
              <li>
                <strong>Ticket imaging:</strong> Capture the entire ticket image
                – front and back – to minimize storage requirements and improve
                retrieval times with optional Image Management Software
              </li>
              <li>
                <strong>Future-ready design:</strong> Easily upgrade select
                models with a software activation key as new features become
                available
              </li>
              <li>
                <strong>Selectable levels of counterfeit detection:</strong> The
                most advanced counterfeit detection in the industry. Patented
                sensors and analytic software analyze each note, catching
                counterfeits that others miss
              </li>
            </ul>
            <Accordion allowZeroExpanded>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Available options</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="p-4">
                    <h3>
                      <strong>Options:</strong>
                    </h3>
                    <ul className="mb-4 ml-4 list-disc">
                      <li>
                        <strong>Two configurable pockets:</strong> Fully
                        functional pockets hold up to 200 notes each. Assign
                        target notes to either pocket for sorting and manual
                        strapping.
                      </li>
                      <li>
                        <strong>Touch panel display:</strong> Easy to use 10.92
                        cm colour touch-screen.
                      </li>
                      <li>
                        <strong>Four levels of memory:</strong> Keep separate
                        totals for: sub-batches, batches, day totals and strap
                        limits.
                      </li>
                      <li>
                        <strong>Unit/value display:</strong> Show totals as
                        dollar value or unit counts.
                      </li>
                      <li>
                        <strong>Adjustable strap stops:</strong> Set separate
                        strap stop limits for each denomination.
                      </li>
                      <li>
                        <strong>Verification:</strong> Count or recount currency
                        without changing batch totals.
                      </li>
                      <li>
                        <strong>Add function:</strong> Perform cumulative
                        counting as needed.
                      </li>
                      <li>
                        <strong>Interface/communications:</strong> USB, RS232,
                        Ethernet.
                      </li>
                      <li>Operating system – Windows CE.</li>
                      <li>
                        <strong>Ticket barcode reading:</strong> Read ticket
                        barcodes at 1,200 documents per minute while also
                        processing banknotes without presorting or facing.
                      </li>
                      <li>
                        <strong>Ticket imaging:</strong> Capture ticket images –
                        front and back – at 1,200 notes per minute.
                      </li>
                      <li>
                        <strong>Counterfeit detection:</strong> A wide range of
                        counterfeit detection sensors are available, including
                        imaging magnetic ink, fluorescence, ultraviolet and IQ™
                        Sensing Technology.
                      </li>
                      <li>
                        <strong>Printers:</strong> Impact or thermal printers
                        provide records of transactions.
                      </li>
                    </ul>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Specs</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <table>
                    <tbody>
                      <tr>
                        <th align="left" colSpan={2}>
                          {' '}
                          Feeder
                        </th>
                      </tr>
                      <tr>
                        <td>Type</td>
                        <td>
                          Automatic feeder with auto-sensing document detection.
                        </td>
                      </tr>
                      <tr>
                        <th align="left" colSpan={2}>
                          {' '}
                          Transport
                        </th>
                      </tr>
                      <tr>
                        <td>Type</td>
                        <td>High-volume straight-through transport path.</td>
                      </tr>
                      <tr>
                        <th align="left" colSpan={2}>
                          {' '}
                          Exit Pocket Capacity
                        </th>
                      </tr>
                      <tr>
                        <td>Maximum Capacity</td>
                        <td>200 documents.</td>
                      </tr>
                      <tr>
                        <th align="left" colSpan={2}>
                          {' '}
                          Physical Specs
                        </th>
                      </tr>
                      <tr>
                        <td>Dimensions</td>
                        <td>
                          35.56 cm H (without hopper) x 35.56 cm W x 40.64 cm D
                        </td>
                      </tr>
                      <tr>
                        <td>Weight</td>
                        <td>19.05 kilograms</td>
                      </tr>
                      <tr>
                        <td>Document Size</td>
                        <td>
                          Height: 6.12 cm to 9.52 cm <br />
                          <br />
                          Length: 11.43 cm to 22.23 cm
                        </td>
                      </tr>
                      <tr>
                        <th align="left" colSpan={2}>
                          {' '}
                          Currency Processing Specs
                        </th>
                      </tr>
                      <tr>
                        <td>Adjustable Processing Speed</td>
                        <td>600, 800, 1,000 and 1,200 notes per minute.</td>
                      </tr>
                      <tr>
                        <td>Denominations Scanned</td>
                        <td>$5, $10, $20, $50 and $100.</td>
                      </tr>
                      <tr>
                        <td>Accuracy</td>
                        <td>99.99+%</td>
                      </tr>
                      <tr>
                        <td>Feeder Capacity</td>
                        <td>
                          Brick Currency: 800 notes <br />
                          <br />
                          Circulated Currency: 700 notes
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <div className="w-full px-4 mt-8 md:pl-8 md:w-1/3 md:mt-0">
          <Image fluid={data.NoteAndTicketScanner.childImageSharp.fluid} />
        </div>
      </div>
    </Layout>
  );
};

NoteAndTicketScanner.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query NoteAndTicketScannerQuery {
    NoteAndTicketScanner: file(
      relativePath: { eq: "note-and-ticket-scanner.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1764) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default NoteAndTicketScanner;
